import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import  Firebaseui  from 'firebaseui'

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyAG8b6TMgqY9J464euQtzDOwBESaXtdpWQ",
  authDomain: "green-mystery-338507.firebaseapp.com",
  projectId: "green-mystery-338507",
  storageBucket: "green-mystery-338507.appspot.com",
  messagingSenderId: "311724700344",
  appId: "1:311724700344:web:793341b5a36db615147a4d",
  measurementId: "G-S5KD3DQLDV"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth;

// eslint-disable-next-line no-restricted-globals
if(process.env.REACT_APP_ENV === "development" && process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "development")
{
  db.useEmulator('localhost', 8080);
  auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
}

export default firebase;
export { db, auth };